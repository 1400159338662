import getConstants from './Constants';

const language = getConstants().LANGUAGE;
const EN_us = {
	language: 'EN_us',
	title: 'We´re here to help!',
	subtitle: 'What channel works best for you?',
	titleCallBack: 'Request a Call Back',
	plaholderCallBack: 'Insert number to call you',
	titleChat: 'Chat',
	subtitleChat: 'With a live agent or bot',
	titleMail: 'Send an Email',
	placeholderMail: 'Insert your email',
	validInputsGeneral: 'Please complete all the fields',
	validInputPhoneNumber: 'Please insert you phone number',
	validInputMail: 'Please insert you mail',
	nameTaskCall: 'Contact widget',
	textTaskCall: 'This is the connection request coming from the widget.',
	titleTaskCall: 'Callback request',
	headerTextTitleChat: 'Chat',
	headerTextSubtitleChat: 'We answer in less that 5 minutes',
	headerTextTitleCall: 'Request a Call Back',
	headerTextSubtitleCall: 'Connecting now …',
	headerTextTitleMail: 'Send an Email',
	headerTextSubtitleMail: 'We reply in less that 24 hours',
	headerTextTitleNoAgent: 'No agent available',
	headerTextSubtitleNoAgent: 'Please try again later',
	clickToCallMessageOne: 'We are calling you from',
    clickToCallMessageTwo: '308 804 969',
    clickToCallMessageThree: 'Please wait a few moments',
    clickToCallMessageFour: 'No agent available at ',
    clickToCallMessageFourOne: 'the moment.',
    clickToCallMessageFive: 'Please email us and we will get ',
    clickToCallMessageFiveOne: 'back to you as soon as possible.',
    clickToCallMessageSix: 'Our office hours are Monday through Friday between 10:00 am and 6:00 p.m.',
    clickToCallMessageSeven: 'Please email us and we will get back to you as soon as possible.',
    clickToMailMessageOne: 'Please fill all the fields below, that',
    clickToMailMessageTwo: 'allows us to better support you',
    clickToMailMessageThree: 'THANK YOU!!',
    clickToMailMessageFour: 'We will respond to your email within 24 hours.',
    clickToMailPlaceholderOne: 'Insert your name here',
    clickToMailPlaceholderTwo: 'Insert your phone here',
    clickToMailPlaceholderThree: 'Please write your question',
    clickToMailButtonOne: 'SEND EMAIL',
};

const PT_pt = {
	language: 'PT_pt',
	title: 'Podemos ajudar?',
	subtitle: 'Escolha a forma de contacto que prefere',
	titleCallBack: 'Ligamos-lhe agora!',
	plaholderCallBack: 'Insira o número',
	titleChat: 'Chat',
	subtitleChat: 'Fale com um operador',
	titleMail: 'Contacte-nos por email!',
	placeholderMail: 'Insira o seu email',
	validInputsGeneral: 'Você precisa preencher todos os campos!',
	validInputPhoneNumber: 'Você precisa preencher seu telefone!',
	validInputMail: 'Você precisa preencher seu email!',
	nameTaskCall: 'Contato via widget',
	textTaskCall: 'Esse é pedido de ligação vindo do widget.',
	titleTaskCall: 'Pedido Callback',
	headerTextTitleChat: 'Chat',
	headerTextSubtitleChat: 'Atendimento em menos de 5 minutos',
	headerTextTitleCall: 'Ligamos-lhe agora!',
	headerTextSubtitleCall: 'A efetuar ligação...',
	headerTextTitleMail: 'Contacte-nos por email!',
	headerTextSubtitleMail: 'Respondemos em menos de 24 horas',
	headerTextTitleNoAgent: 'Nenhum agente disponível',
	headerTextSubtitleNoAgent: 'Não podemos lhe atender agora',
	clickToCallMessageOne: 'Estamos a ligar do número:',
    clickToCallMessageTwo: '308 811 909',
    clickToCallMessageThree: 'Por favor aguarde mais alguns segundos',
    clickToCallMessageFour: 'Operador não disponível ',
    clickToCallMessageFourOne: 'neste momento.',
    clickToCallMessageFive: 'Envie por favor email e entraremos em  ',
    clickToCallMessageFiveOne: 'contacto consigo no mais curto espaço de tempo.',
    clickToCallMessageSix: 'O nosso horário de atendimento é Segunda a Sexta entre as 10:00 e as 18:00.',
    clickToCallMessageSeven: 'Envie por favor email e entraremos em contacto consigo no mais curto espaço de tempo.',
    clickToMailMessageOne: 'Por favor preencha os campos',
    clickToMailMessageTwo: 'abaixo para melhor atendimento.',
    clickToMailMessageThree: 'OBRIGADO!!',
    clickToMailMessageFour: 'Responderemos ao seu email num prazo máximo de 24h.',
    clickToMailPlaceholderOne: 'Insira o seu nome',
    clickToMailPlaceholderTwo: 'Insira o seu telemóvel',
    clickToMailPlaceholderThree: 'Descreva a sua questão',
    clickToMailButtonOne: 'ENVIAR EMAIL',
};

function getText() {
	switch(language) {
	  case 'EN_us':
	    return EN_us;
	    break;
	  case 'PT_pt':
	    return PT_pt;
	    break;
	  default:
	    return EN_us;
	}
}

export default getText;